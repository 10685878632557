.insight-stacked-bar-chart-container {
    display: flex;
    align-items: center;
    gap: 36px;

    .bar-container {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 91px;
    }

    .bar {
        height: 16px;
        border-radius: 8px;
    }

    .legend {
        display: flex;
        flex-direction: column;


        .legend-item {
            display: flex;
            align-items: center;
            gap: 17px;

            .dot {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                flex-shrink: 0;
            }

            .label {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;

                .value-text {
                    text-align: start;
                }

                .label-text {
                    text-align: start;
                }
            }
        }
    }
}