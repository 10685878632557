@use 'src/sass/colors'as *;

.dropdown {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid $grayTwo;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: $black;
    background-color: $white;
    cursor: pointer;
  }
  
  .dropdown:focus {
    outline: none;
    border-color: $Light_Blue_PRI;
  }
  