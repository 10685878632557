@use 'src/sass/colors' as *;

.year-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 0.25rem;
    min-height: 40px;
    padding: 4px 10px;
    box-sizing: border-box;

    .year-editable-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .year-left-div {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 4px;
            flex-grow: 1;
        }

        .year-right-div {
            align-self: center;
        }
    }

    .content-clip-wrapper {
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-wrap: nowrap;
        gap: 4px;
    }

    .year-quarter-container.year-custom-metric-cell {
        top: 35vh;
        left: 5vw;
    }

    .year-quarter-container.year-net-benefit-cell {
        top: 35vh;
        left: 42vw;
    }

    .year-quarter-container {
        position: fixed;
        // top: 35vh;
        // left: 25vw;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 280px;
        height: 100px;
        overflow-y: auto;
        z-index: 10;
        padding: 5px;



        .year-quarter-header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-self: flex-start;
            padding: 0.75rem 1.25rem;
            gap: 16px;

            .arrow-img {
                height: 16px;
                width: 16px;
            }

            .year-text {
                font-family: Roboto;
                font-size: 16px;
                font-weight: 300;
                line-height: 18px;
                letter-spacing: -0.019em;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
            }

            .close-button {
                float: right;
                background: none;
                height: 16px;
                width: 16px;
                cursor: pointer;
                padding-right: 5px;
                position: absolute;
                top: 16px;
                right: 10px;
                border: none;
                background-color: transparent;
                font-size: 16px;
                cursor: pointer;
                color: #999;
            }

        }

        .radio-div {
            display: flex;
            justify-content: space-around;
            padding: 10px;
            margin-top: 5px;
            margin-bottom: 10px;

            .radio-label {
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;
                font-size: 16px;
            }

            .radio-view {
                width: 18px;
                height: 18px;
                accent-color: $Light_Blue_PRI;
            }
        }
    }

    .year-content-wrapper {
        display: flex;
        padding: 0.125rem 0.375rem;
        align-items: center;
        border-radius: 0.25rem;
        background: $background;

        .break-item {
            word-break: break-all;
            white-space: normal;
        }
    }

    .cross-icon {
        width: 1rem;
        height: 1rem;
        margin-left: 0.25rem;
        padding: 0.25rem;
        box-sizing: border-box;
    }

    .add-tag-wrapper {
        display: flex;
        cursor: pointer;

        .dropdown-icon {
            width: 1.2rem;
            height: 1.2rem;
            box-sizing: border-box;
        }
    }
}

.year-wrapper.editable {
    background: $white;
    box-sizing: border-box;
    border-right: 0;
    border-left: 0;
}