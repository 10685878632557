@use 'src/sass/colors' as *;

.eps-approver-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 0.25rem;
    min-height: 40px;
    padding: 4px 10px;
    box-sizing: border-box;
    background: $white;
    height: 100% !important;
    border: 2px solid rgb(33 150 243 / 59%);
    border-radius: 0.25rem;

    .editable-wrapper {
        display: flex;
        width: 100%;

        .left-div {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            flex-grow: 1;
        }

        .right-div {
            align-self: center;
        }
    }

    .add-approver-input {
        color: $grayTwo;
        color: #666;
        line-height: 180%;
        padding: 0 0.5rem;
        outline: none;
        border: 1px solid $Light_Blue_PRI;
    }

    .add-approver-input:focus {
        outline: none;
    }

    .add-approver-wrapper {
        display: flex;
        cursor: pointer;

        .plus-icon {
            width: 1.5rem;
            height: 1.5rem;
        }

        .dropdown-icon {
            width: 1.5rem;
            height: 1.5rem;
            box-sizing: border-box;
            padding: 0.27rem;
        }
    }
}

.eps-approver-search-container {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 280px;
    overflow-y: auto;
    z-index: 10;

    .approvers-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        padding: 0.75rem 1.25rem;

        .cross-icon {
            width: 1.5rem;
            height: 1.5rem;
            box-sizing: border-box;
            cursor: pointer;
            padding: 0.25rem;
        }
    }

    .approvers-search-div {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-items: center;
        gap: 12px;
        padding: 0.75rem 1.25rem;
        background: $backgroundThree;

        .search-input {
            display: flex;
            padding: 0;
            align-items: center;

            input {
                width: 100%;
                border: 1px solid $graySix;
                outline: none;
                border-radius: 0.25rem 0 0 0.25rem;
                font-family: "Roboto";
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 250%;
                color: $grayTwo;
                border-right: none;
                padding-left: 0.75rem;
            }

            .clear-search-button {
                background: white;
                border: 1px solid $graySix;
                border-left: none;
                cursor: pointer;
                border-radius: 0 0.25rem 0.25rem 0;
            }
        }

        .clear-search-icon {
            width: 2rem;
            height: 2rem;
            padding: 10px;
            box-sizing: border-box;
        }

        .search-button {
            height: 2.375rem;
            border-radius: 0.25rem;
            background: $Light_Blue_PRI;
            color: white;
            border: none;
            cursor: pointer;
        }
    }

    .approvers-content-container {
        display: flex;
        padding: 1.25rem 1.25rem 1.5rem 1.25rem;
        align-items: center;
        gap: 0.75rem;
        align-self: stretch;
        flex-wrap: wrap;
        overflow: auto;
        max-height: 10rem;
        box-sizing: border-box;
        align-items: flex-start;

        .approvers-content-item {
            padding: 0.125rem 0.375rem;
            background: $background6;
            border-radius: 0.25rem;
            color: $grayTwo;
            cursor: pointer;
        }

        .dropdown-empty {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 6.5rem;
        }
    }
}

.eps-approver-content-clip-wrapper {
    display: flex;
    overflow: hidden;
    flex-wrap: nowrap;
    gap: 4px;
    padding: 4px 0px;

    &.wrap {
        flex-wrap: wrap;
    }

    .more-link-button {
        border: none;
        cursor: pointer;
        background-color: $white;
        color: $Light_Blue_PRI;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .more-link-button:hover {
        background-color: $white;
    }
}

.eps-approver-content-wrapper {
    display: flex;
    padding: 0.125rem 0.375rem;
    align-items: center;
    border-radius: 0.25rem;
    background: $background;

    .break-item {
        word-break: break-all;
        white-space: normal;
    }

    .cross-icon {
        width: 1rem;
        height: 1rem;
        margin-left: 0.25rem;
        padding: 0.25rem;
        box-sizing: border-box;
    }
}