@use 'src/sass/colors' as *;

.header-container {
    display: flex;
    flex-direction: row;
    padding: 12px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 24px;
    align-self: stretch;
    background: $white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: sticky;
    top: 0;
    z-index: 1000;
    box-sizing: border-box;
}

.logo {
    color: $black;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: uppercase;
}

.back {
    height: 10px;
    width: 12px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.signal-title-container {
    padding-right: 10px;
}

.nav-item-container {
    display: flex;
    padding: 2px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    &.active {
        border-bottom: 3px solid $Light_Blue_PRI;
    }
}

.nav-item {
    color: $grayTwo;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.266px;

    &.active {
        color: $Light_Blue_PRI;
        font-family: "Roboto";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.266px;
    }
}

.customer-summary-button {
    display: flex;
    flex-direction: row;
    height: 28px;
    padding: 2px 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid $Light_Blue_PRI;
}

.left-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 1;
}

.transactional-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    background: $background;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.csat-customer-container {
    display: flex;
    flex-direction: row;
    height: 300px;
}

.csat-left-container {
    display: flex;
    flex-direction: column;
    flex: 10;
    height: 300px;
}

.csat-graph-container {
    padding: 24px;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: $white;
    margin-top: 12px;
}

.customer-effort-score {
    padding: 36px 0px;
    //align-items: center;
    gap: 16px;
    //align-self: stretch;
    border-radius: 8px;
    background: $white;
    margin-left: 16px;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
}

.customer-effort-score-container {
    display: flex;
    flex-direction: column;
    flex: 3;
    height: 300px;
}

.title-container {
    margin-left: 16px;
}

.cef-container {
    padding: 0px 12px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.progress-container {
    padding: 0px 12px;
}

.top-chart-container {
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.bottom-chart-container {
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin-top: 0.5rem;
}

.charts-top-text {
    color: $black;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: uppercase;
    padding-top: 8px;
    padding-bottom: 8px;
}

.csat-touchpoints-container {
    height: 250px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 8px;
    background: $white;
    margin-top: 12px;
}

.relational-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 0.5rem;

    .heading-div {
        display: flex;
        align-items: center;
        height: 32px;

        .current-link-container {
            display: flex;
            align-items: center;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.content-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    align-items: center;
    background-color: $background;
    padding-top: 0.5rem;
}

.rel-left-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 6;
}

.nps-csat-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.transactional-link-container {
    display: flex;
    align-items: center;

    img {
        width: 20px;
        height: 20px;
    }
}

.rel-right-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    flex: 3;
}

.nps-title-style {
    color: $black;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: uppercase;
}

.nps-trend-container {
    padding: 24px;
    align-self: stretch;
    border-radius: 8px;
    background: $white;
    height: 26.5rem;
}

.trend-img {
    width: 16px;
    height: 16px;
    margin-right: 2px;
}

.trend-container-cef {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 18px;
}

.trend-container-digital {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 18px 12px;
}

.digital-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.legends-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

:root {
    --loyal-color: #0561D1;
    --neutral-color: #D5E6FE;
    --atRisk-color: #83B6FD;
}

.chartContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chartDiv {
    height: 91px;
    width: 91px;
}

.digital-container {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    background: $background;
}

.digital-chart-container {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: $white;
}

.widget-container {
    display: flex;
    padding: 32px 12px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    background: $white;
    margin-top: 12px;
    height: 35vh;
}

.widget-outer-container {
    margin-top: 12px;
    flex: 1;
}

.widgets {
    flex: 1;
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 24px
}


@media screen and (max-width: 768px) {
    .column {
        flex-basis: calc(50% - 10px);
    }
}

@media screen and (max-width: 480px) {
    .column {
        flex-basis: calc(100% - 10px);
    }
}

@media screen and (max-height: 650px) {
    .nps-trend-container {
        height: 24rem;
    }
}