@import '/src/sass/colors.scss';

.filter-container {
    display: flex;
    flex-direction: row;
    width: 210px;

    .react-datepicker-wrapper {
        .react-datepicker__input-container {
            display: none;
        }
    }

    .react-datepicker__tab-loop {
        .react-datepicker-popper {
            left: -124px !important;
            top: -6px !important;
            border: 1px solid $tertiaryGrey;
            border-radius: 8px;
        }
    }

    .react-datepicker {
        border: 1px solid $tertiaryGrey;
    }

    .react-datepicker__header {
        background-color: $white; // Optional light background for the header
        border-bottom: 1px solid $white;
    }

    .react-datepicker__month-container {
        border-radius: 0; // No rounded corners for the month container
    }

    .react-datepicker__day,
    .react-datepicker__month-text {
        border-radius: 0; // No rounded corners for individual day/month cells
    }

    .react-datepicker__month-text.current-month::after {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        background-color: $Light_Blue_PRI; // Dot color
        border-radius: 50%; // Make it circular
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .react-datepicker__triangle {
        display: none; // Remove the triangular arrow from the popup
    }
}

.calendar-icon {
    background: $white;
    border: 1px solid $tertiaryGrey;
    border-radius: 4px;
    color: $grayTwo;
    padding: 4px 8px;
    margin-left: 4px;
}