@use 'src/sass/colors'as *;
.csat-touchPoint-editor-wrapper {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    padding: 0rem 1rem;
    align-items: center;
    background: $white;

    .text-container {
        flex: 1 1;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .plus-icon-image {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        position: absolute;
        padding: 0 1.5rem;
        right: 0;
    }
}

.csat-touchPoint-right-sidebar{
    .sub-touchpoint-name{
        display: flex;
        width: 100%;
        padding: 8px 20px;
        align-items: center;
        align-self: stretch;
        box-sizing: border-box;
        border-radius: 0.25rem;
        border: 0.75px solid $grayNine;
        background: $white;
        outline: none;
        color: $grayTwo;
        height: 40px;
    }
    .sub-touchpoint-score{
        display: flex;
        width: 6.5rem;
        padding: 8px 20px;
        align-items: center;
        align-self: stretch;
        box-sizing: border-box;
        border-radius: 0.25rem;
        border: 0.75px solid $grayNine;
        background: $white;
        outline: none;
        color: $grayTwo;
        height: 40px;
    }

    .all-sub-touchpoint-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow: auto;
        gap: 1.75rem;
        align-items: flex-start;

        .each-sub-touchpoint-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            width: 100%;

            .sub-touchpoint-value-wrapper {
                display: flex;
                gap: 0.5rem;
                width: 100%;
            }
        }
    } 
}