@use 'src/sass/colors'as *;

.right-sidebar {
    display: flex;
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    width: 25.5rem;
    background: $PageBG;
    flex-direction: column;
    align-items: flex-start;
    padding: 3.75rem 2.5rem;
    box-sizing: border-box;
    gap: 1.75rem;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.10);
    z-index: 2000;

    .rsb-loader {
        display: flex;
        border: 1px solid $tertiaryGrey;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .cross-icon {
        width: 22px;
        height: 22px;
        box-sizing: border-box;
    }

    .header-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;

        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;

        .header-title-wrapper {
            display: flex;
            justify-content: space-between;
        }
    }
}