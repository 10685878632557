@use 'src/sass/colors'as *;

.dashboard-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;

    .dashboard-header {
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid #ddd;
        flex-shrink: 0;
        box-sizing: border-box;

        .left-div {
            display: flex;
            align-items: center;
            gap: 4px;

            .menu-toggler {
                width: 24px;
                height: 24px;
                padding: 12px;
            }

            .org-logo {
                width: 140px;
                height: 20px;
            }
        }

        .right-div {
            display: flex;
            align-items: center;

            .miscellaneous-menu-div {
                display: flex;
                align-items: center;
                gap: 8px;
                padding-right: 8px;

                .logo-wrapper {
                    width: 24px;
                    height: 24px;
                    padding: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 6px;
                    position: relative;

                    &.active {
                        background: $whiteTwo;
                        border-bottom: 4px solid $Light_Blue_PRI;
                        padding-bottom: 8px;
                    }

                    &:hover {
                        background: $whiteTwo;
                        white-space: nowrap;
                    }

                    &:hover::after {
                        position: absolute;
                        content: "Coming Soon";
                        top: 45px;
                        background: $grayTwo;
                        padding: 5px 10px;
                        border-radius: 6px;
                        font-family: "Roboto";
                        font-size: 12px;
                        color: $white;
                    }

                    &:hover::before {
                        position: absolute;
                        content: "";
                        top: 36px;
                        left: 50%;
                        transform: translateX(-50%);
                        border-width: 5px;
                        border-style: solid;
                        border-color: transparent transparent $grayTwo transparent;
                        /* Creates the triangle */
                    }
                }
            }

            .logout-div {
                font-family: "Roboto";
                font-size: 12px;

                .dropdown-content {
                    display: none;
                    position: absolute;
                    right: 0;
                    top: 44px;
                    background: white;
                    padding: 6px 6px 6px 12px;
                    width: 65px;
                    border: 1px solid $lightGrayTwo;
                    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                    border-radius: 6px;
                }

                &:hover {
                    .dropdown-content {
                        display: flex;
                    }
                }

                img {
                    width: 28px;
                    height: 28px;
                    padding: 12px;
                    border-radius: 50%;
                }

                border-left: 1px solid $lightGrayTwo;
                display: flex;
                align-items: center;
                margin-right: -12px;
            }
        }
    }

    .dashboard-content {
        display: flex;
        flex: 1;
        overflow: hidden;
        box-sizing: border-box;

        .main-container {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: hidden;
            background: $background;
            padding-top: 16px;
            box-sizing: border-box;

            .main-content {
                flex: 1;
                padding: 0 16px;
                background: $background;
                transition: margin-left 0.3s ease;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }
    }
}