@use 'src/sass/colors' as *;

.table-details-container {
  width: 100%;
  height: 100%;

  .data-table {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;
  }

  .ag-theme-alpine {

    .ag-header {
      position: sticky;
      top: 0;
      background-color: $backgroundThree;
      border: 1px solid $backgroundTwo;
    }

    .ag-root-wrapper {
      border-radius: 6px;
      border: 1px solid $backgroundTwo;
      height: 500px;
      width: 100%;
    }

    .ag-header-cell {
      justify-content: center;
      text-align: center;
      background-color: inherit;
      color: $Light_Blue_PRI;
      font-family: "Roboto";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
    }

    .ag-cell {
      display: flex;
      align-items: center;
      color: $grayTwo;
      font-family: "Roboto";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .ag-cell.detractor-cell {
      padding: 0;
    }

    .ag-cell.quarter-year-cell {
      padding: 0;
    }

    .ag-cell.quarter-year-cell-checkbox {
      padding-right: 0;
    }

    .ag-cell.multi-select-cell {
      padding: 0;
    }

    .custom-header-center .ag-header-cell-label {
      justify-content: center;
      text-align: center;
    }

    .ag-cell.custom-cell-center {
      justify-content: center;
      text-align: center;
    }
    .custom-align-center-header .ag-header-cell-label{
      justify-content: center;
      text-align: center;
    }
    .custom-align-center-cell.ag-cell{
      justify-content: center;
      text-align: center;
    }
  }

  .grid-overlay-btn {
    pointer-events: all;
    background: none;
    border: none;
    color: $Light_Blue_PRI;
    cursor: pointer;
  }
}