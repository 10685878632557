@use 'src/sass/colors'as *;

.common-input {
  display: flex;
  padding: 12px;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid $borderOne;

  &:focus {
    border-color: $grayOne;
    outline: none;
    background-color: $white;
  }
}