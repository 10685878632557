@use 'src/sass/colors'as *;

.vista-container {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    align-self: stretch;
    background: $background;
    
    .vista-trademark{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .legal{
        display: flex;
        align-items: center;
        gap: 24px;

        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: $grayFour;
    }
}

.img-container {
    width: 76px;
    height: 11px;
    margin-left: 10px;
}

.powered-text {
    color: $grayFour;
    text-align: center;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: lowercase;
}