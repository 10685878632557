@use 'src/sass/colors'as *;

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  height: 54px;
  box-sizing: border-box;
  background-color: $darkBlue;
}

.navbar-nav {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-item {
  margin-right: 1rem;
}

.navbar-link {
  color: $white;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  text-transform: capitalize;
  text-decoration: none;
}

.title-company {
  color: $whiteOne;
  font-weight: bold;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-in-out;
  width: fit-content;
}

.logo {
  width: 156px;
  height: 23px;
}

@media screen and (max-width: 768px) {
  .column {
    flex-basis: calc(50% - 10px);
  }
}

@media screen and (max-width: 480px) {
  .column {
    flex-basis: calc(100% - 10px);
  }
}