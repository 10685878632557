@import './colors.scss';
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

.pointer{
  &:not(:disabled){
    cursor: pointer;
  }
}

.bg-gray{
  background-color: $grayThree;
}

.locked{
  pointer-events: none;
  user-select: none;
  position: relative;
}

.no-data-msg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}