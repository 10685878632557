@use 'src/sass/colors' as *;
.custom-select-cell-wrapper{
    position: relative;
    width: 100%;
    height: 100%;

    .dropdown-image{
        cursor: pointer;
        width: 0.75rem;
        height: 0.75rem;
    }

    .selected-value-display-wrapper{
        border: 2px solid rgb(33 150 243 / 59%);
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        cursor: pointer;
        background-color: $white;
        width: 100%; 
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        border-radius: 0.25rem;
    }
}
.custom-dropdown-wrapper{
    position: absolute;
    border: 1px solid #ccc;
    background: $white;
    z-index: 1;
    overflow-y: auto;
    box-sizing: border-box;

    .custom-option-wrapper{
        cursor: pointer;
    }
    .custom-option-content-wrapper{
    display: flex;
    width: 100%;
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
    gap: 0.25rem;
    }
    .custom-option-content-wrapper:hover{
        background-color: rgba(33, 150, 243, 0.2);
    }
    .custom-option-content-wrapper.active{
        background-color: rgba(33, 150, 243, 0.3);
    }

    .custom-option-content{
        color: $grayEight;
    }

    .default-option-content{
        padding: 0.25rem 0.5rem;
        box-sizing: border-box;
        gap: 0.25rem;
        color: $grayEight;
    }
    .default-option-content:hover{
        background-color: rgba(33, 150, 243, 0.2);
    }
    .default-option-content.active{
        background-color: rgba(33, 150, 243, 0.3);
    }
}