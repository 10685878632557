@use 'src/sass/colors'as *;

.login-container {
    height: 88vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $background;
}

.form-container {
    display: flex;
    padding: 64px 40px;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    background: $white;
}

.form-style {
    display: flex;
    flex-direction: column;
    width: 226px;
}

.logo-vista {
    width: 159.001px;
    height: 23.401px;
    margin-bottom: 24px;
}

.forgot-password {
    align-self: flex-start;
}

.input-container {
    margin-bottom: 12px;
    width: 226px;
}

.button-style {
    display: flex;
    height: 40px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    background: $Light_Blue_PRI;
    border-width: 0px;
    color: $white;
    margin-top: 12px;
}

.button-style:disabled {
    background-color: $LightBlue;
}

.clickable-text {
    cursor: pointer;
    user-select: none;
    transition: color 0.3s;
}

.normal-text {
    color: $grayTwo;
    text-align: center;
    font-family: "Roboto";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.19px;
}

.link-text {
    color: $Light_Blue_PRI;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.19px;
}

.link-text:hover {
    color: darkblue;
}

.link-text:active {
    color: darkblue;
}