@use 'src/sass/colors' as *;

.Heading1 {
    font-family: "Roboto";
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.Heading2 {
    font-family: "Roboto";
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
}

.Heading3 {
    font-family: "Roboto";
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
}

.Heading4 {
    font-family: "Roboto";
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
}

.Heading5 {
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    text-transform: uppercase;
}

.Heading6 {
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
}

.Heading7 {
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: uppercase;
}

.Heading8 {
    font-family: "Roboto";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.Page-Heading {
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.Section-Heading {
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.body1 {
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

//figma as body4
.body2 {
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.body3 {
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%
}

.body4 {
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%
}

//as per figma 
.body5 {
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.caption1 {
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
}

//as per figma 
.caption2 {
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.caption3 {
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
}

.caption4 {
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
}

.tooltip {
    font-family: "Roboto";
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
}

.tooltip2 {
    font-family: "Roboto";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.body7 {
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.body8 {
    font-family: "Roboto";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.body9 {
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.body10 {
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.blackTextColor {
    color: $black;
}

.align-self-right {
    align-self: flex-end;
}

.align-self-center {
    align-self: center;
}

.uppercase-text {
    text-transform: uppercase;
}

.capitalize-text {
    text-transform: capitalize;
}

.break-word {
    word-break: break-word;
}

.capitalize-first {
    text-transform: lowercase;

    &::first-letter {
        text-transform: uppercase;
    }
}