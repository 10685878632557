@use 'src/sass/colors'as *;
.app-btn{
  font-family: Roboto;
  font-size: 12px;
  line-height: 18px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid $Light_Blue_PRI;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  &:disabled {
    opacity: .6;
    cursor: default;
    pointer-events: none;
  }

  &.primary{
    padding: 9px 20px;
    color: $white;
    background: $Light_Blue_PRI;
  }

  &.secondary{
  padding: 9px 7px;
  color: $grayTwo;
  background: #FFF;
  }

  &.tertiary{
    background: $white;
  }
}