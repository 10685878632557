@use 'src/sass/colors' as *;

.signals-detailed-container {
  display: flex;
  flex-direction: column;
  background: $background;
  flex: 1;

  .signal-details {
    display: flex;
    flex-direction: row;
    flex: 3;
    gap: 1rem;

    .touchPoints-details {
      display: flex;
      flex-direction: column;
      flex: 2;
      gap: 1rem;
      min-height: 200px;

      .touchPoints-details-sub {
        display: flex;
        flex-direction: row;
        flex: 2;
        gap: 1rem;

        .touchPoint-row {
          display: flex;
          flex: 1;
          flex-direction: column;
          background: $white;
          padding: 12px 16px 12px 16px;
          border-radius: 8px;
        }
      }

      .survey-responses-details {
        display: flex;
        flex-direction: row;
        flex: 2;
        gap: 1rem;

        .survey-responses-details-sub {
          display: flex;
          flex-direction: column;
          flex: 1;
          gap: 1rem;

          .survey-block {
            display: flex;
            flex-direction: column;
            flex: 1;
            background: $white;
            gap: 12px;
            border-radius: 8px;
            min-height: 120px;
          }

          .response-block {
            display: flex;
            flex-direction: column;
            flex: 1;
            background: $white;
            gap: 12px;
            border-radius: 8px;
            padding: 0px 16px 0px 26px;
            justify-content: center;
            min-height: 120px;
          }
        }
      }
    }

    .feedback-details {
      display: flex;
      flex-direction: column;
      flex: 1;

      .table-feedback {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1rem;
        margin-top: 1rem;

        .signal-detractor-wrapper {
          height: 332px;
          width: 100%;

          .table-col {
            display: flex;
            flex: 1;
            flex-direction: column;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .titleHeader {
    margin-top: 0.8rem;
    margin-left: 0.2rem;
  }

  .chart-details {
    display: flex;
    flex-direction: row;
    flex: 4;
    gap: 1rem;

    .chart-details-item {
      display: flex;
      flex-direction: column;
      flex: 1;
      border-radius: 8px;
      justify-content: space-between;
      background: $white;

      .chart-nps-row {
        display: flex;
        flex-direction: row;
        padding: 1rem 1rem 0rem 1rem;
        gap: 1rem;
        justify-content: space-between;

        .chart-nps-trend {
          display: flex;
          flex-direction: row;
          gap: 8px;

          .chart-trend {
            width: 16px;
            height: 16px;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}