@use 'src/sass/colors' as *;

.relational-container {
    .total-surveys-container {
        display: flex;
        padding: 24px;
        flex-direction: column;
        flex: 1 0 0;
        align-self: stretch;
        justify-content: space-between;
        border-radius: 8px;
        background: $white;

        .upper-div {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .upper-div-left {
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;

                .survey-title {
                    display: flex;
                    padding: 6px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    border: 0.7px solid var(--Light-Blue, #3185FC);
                    background: rgba(49, 133, 252, 0.04);
                }
            }

            .survey-donut-chart {
                margin-top: 12px;
                margin-bottom: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 24px;

                .lg-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 16px;

                    .legend-container {
                        display: flex;
                        align-items: center;
                        gap: 16px;

                        .legend-circle {
                            height: 12px;
                            width: 12px;
                            border-radius: 50%;
                        }

                        .label-container {
                            display: flex;
                            flex-direction: row;
                            align-items: baseline;
                            gap: 2px;

                            .value-container {
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }

        .lower-div {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .trend-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-top: -12px;
            }
        }
    }
}