@import '/src/sass/colors.scss';


.tableHeader {
    display: flex;
    flex-direction: column;
    height: 100%;

    .titleView {
        padding: 12px 10px;
        gap: 10px;
        border-radius: 8px 8px 0px 0px;
        border: 1px solid $backgroundTwo;
        background: $backgroundThree;
        width: 100%;
        box-sizing: border-box;
        height: 44px;

        .title {
            color: $Light_Blue_PRI;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            text-transform: uppercase;
        }
    }

    .chart-container {
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 25px;
        border-radius: 0px 0px 6px 6px;
        border: 1px solid #E2E0E0;
        background: #FFF;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        width: 100%;
        box-sizing: border-box;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 0px;
        }
    }
}