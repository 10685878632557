@use 'src/sass/colors'as *;

.sidebar {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem 1.5rem 1rem;
    gap: 8px;
    box-sizing: border-box;
    width: 236px;
    background: $white;
    color: white;
    transition: width 0.3s ease;
    height: 100%;
    overflow-y: auto;
    border: 1px solid $lightGrayTwo;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);

    &::-webkit-scrollbar {
        width: 0;
    }

    a {
        text-decoration: none;
        width: 100%;
    }

    .logo-label-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .parent-item {
        display: flex;
        height: 48px;
        padding: 12px;
        align-self: stretch;
        border-radius: 6px;
        background: $white;
        box-sizing: border-box;

        &:hover {
            background: $whiteTwo;
        }

        &.item-active {
            background: $whiteTwo;
            border-left: 4px solid $Light_Blue_PRI;
            box-sizing: border-box;
            padding-left: 8px;
        }

        &.submenu-wrapper {
            height: auto;
            flex-direction: column;
            padding: 0;

            &.item-active {
                background: none;
                border: 0;
                box-sizing: border-box;
            }

            &:hover {
                background: none;
            }
        }
    }

    &.collapsed {
        width: 80px;

        .submenu-as-main-div {
            justify-content: center;
            padding: 12px;

            &:hover {
                background: $whiteTwo;
            }
        }

        .submenu {
            display: none;

            &.show {
                display: none;
            }
        }
    }


    .submenu-as-main-div {
        display: flex;
        align-items: center;
        border-radius: 6px;

        &:hover {
            background: $whiteTwo;
        }

        .submenu-dropdown-toggle {
            display: flex;
            box-sizing: border-box;
            align-items: center;
            height: 48px;
            cursor: pointer;
            padding-right: 12px;
        }

        &.open {
            border-radius: 6px;
        }

        &.subitem-active {
            background: $whiteTwo;
            border-left: 4px solid $Light_Blue_PRI;
            padding-left: 8px;

            &.sidebar-expand {
                .submenu-main-logo-label-wrapper {
                    padding-left: 0;
                }
            }
        }

        .submenu-logo-text-div {
            width: 100%;
        }

        .submenu-main-logo-label-wrapper {
            display: flex;
            gap: 12px;
            align-items: center;
            padding: 12px;

            .submenu-main-label-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }
    }

    .submenu {
        .submenu-item-wrapper {
            display: flex;
            position: relative;
            height: 36px;
            align-items: center;
            padding: 12px 12px 12px 16px;
            box-sizing: border-box;

            &.active {
                background-color: $whiteTwo;
                border-radius: 6px;
            }

            .submenu-item {
                display: flex;
                align-items: center;

                >div {
                    width: 100%;
                }

                .submenu-item-label {
                    padding-left: 36px;
                    position: absolute;
                    box-sizing: border-box;
                }
            }
        }

        display: none;

        &.show {
            display: block;
            padding-top: 8px;
        }
    }

    .submenu-item-wrapper:hover {
        background: $whiteTwo;
    }
}