@use 'src/sass/colors' as *;

.date-picker-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid $Light_Blue_PRI;
    padding: 8px;
    background-color: $white;

    .react-datepicker__header {
        background-color: $white; // Optional light background for the header
        border-bottom: 1px solid $white;
    }

    .react-datepicker__month-container {
        border-radius: 0; // No rounded corners for the month container
    }

    .react-datepicker__day,
    .react-datepicker__month-text {
        border-radius: 0; // No rounded corners for individual day/month cells
    }

    .react-datepicker__month-text.current-month::after {
        content: ""; // Add a dot
        display: block;
        width: 5px;
        height: 5px;
        background-color: $Light_Blue_PRI; // Dot color
        border-radius: 50%; // Make it circular
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .react-datepicker__triangle {
        display: none; // Remove the triangular arrow from the popup
    }
}

.custom-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    cursor: pointer;
    width: 100%; // Ensures it spans the container
}

.monthYearText {
    flex: 1;
    border: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    padding: 0;
    font-family: Roboto;
}

.dropdown-icon {
    cursor: pointer;
    height: 15px;
    width: 15px;
    padding-left: 6px;
}

.react-datepicker {
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;

    .react-datepicker__month-text {
        position: relative;
        padding: 5px 5px;
        cursor: pointer;
        border-radius: 4px;
        text-transform: uppercase;
    }

    .react-datepicker__month-text:hover {
        background-color: $borderOne; // Light hover effect
    }

    // Style for the selected month
    .selected-month {
        background-color: $Light_Blue_PRI;
        color: $white;
        border-radius: 15px;
    }
}

.calendarContainer {
    background-color: $white;
}