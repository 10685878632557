$darkBlue: #022550;
$Purple_Graph2: #C5C4FF;
$Green3: #D8F3D2;
$LightBlue: #D5E6FE;
$td_blue_dark: #3D89DF;
$td_green_dark: #40B869;
$td_green_dark_background: #2C9D100A;
$td_orange_dark: #F77A4A;
$td_red_dark: #DD524D;
$td_red_dark_background: #FF4B4B14;
$td_yellow_dark_background: #F5B54614;
$white: #FFF;
$black: #000;
$grayOne: #888;
$grayTwo: #666;
$grayThree: #EEEEEE;
$grayFour: #999;
$grayFive: #B1B1B1;
$graySeven: #F8FAFB;
$graySix: #ABABAB;
$grayEight: #4A4A4A;
$grayNine: #9B9B9B;
$lightGrayOne: #ddd;
$lightGrayTwo: #EAECF0;
$whiteOne: #f5efef;
$whiteTwo: #F9FAFB;
$blueOne: #176DE8;
$Light_Blue_PRI: #3185FC;
$background: #F2F3F6;
$backgroundTwo: #E2E0E0;
$backgroundThree: #F1F5FE;
$backgroundFour: #FBFBFB;
$background6: #F2F2F2;
$background7: #FCFCFC;
$PageBG: #F4F5F8;
$borderOne: #D6D6D6;
$lightRed: #FDC5C6;
$Graph_BG: #F1F5F9;
$blackHeading: #343434;
$labelBackground: #52A3FF;
$pendingLabelBg: #CFE5FF;
$delayedLabelBg: #FFE8BF;
$cancelledLabelBg: #FFDFDF;
$completedLabelBg: #D8F3D2;

//new colors as per the new design
$primary_grey: #222;
$secondary_grey: #666;
$td_yellow_dark: #F5B546;
$Green: #5EBA47;
$red: #FF4B4B;
$darkRed: #E10040;
$tertiaryGrey: #D9D9D9;