@use 'src/sass/colors' as *;

.custom-ag-grid-css.ag-theme-alpine {
    .ag-root-wrapper {
        border-radius: 0.5rem;
        border: 1px solid $backgroundTwo;
    }

    .ag-header {
        border-bottom: 1px solid $backgroundTwo; //override style of default border
    }

    .ag-header-cell.first-column-header {
        .ag-header-cell-label {
            justify-content: flex-start;
        }

        .ag-cell {
            border-right: 1px solid $backgroundTwo;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.625rem 0.75rem;
            color: $blackHeading;
            font-family: "Roboto";
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
            white-space: normal;
            word-wrap: break-word;
        }

        .ag-row {
            border: 0 0 0 1px solid $backgroundTwo;
        }

        .ag-pinned-left-header {
            border-right: 0;
        }

        .ag-row:first-child {
            border-top: 0;
        }

        .ag-pinned-left-cols-container::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 0.75rem;
            background: linear-gradient(to right, #C8C8C8 4.49%, #FFF 94.31%);
            pointer-events: none;
            z-index: 1;
        }

        .ag-pinned-left-header::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 0.75rem;
            background: linear-gradient(to right, #C8C8C8 4.49%, #FFF 94.31%);
            /* Gradient effect */
            pointer-events: none;
            z-index: 1;
        }

        .ag-row-odd {
            background-color: $white;
        }
    }

    .ag-header-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.625rem 0.75rem;
        text-align: center;
        gap: 0.625rem;
        border-right: 1px solid $backgroundTwo;
        background: $backgroundThree;
        color: $Light_Blue_PRI;
        font-family: "Roboto";
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        box-sizing: border-box;

        .ag-header-cell-label {
            justify-content: center;
        }
    }

    .ag-header-cell.header-customer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.625rem 0.75rem;
        text-align: center;
        gap: 0.625rem;
        border-right: 1px solid $backgroundTwo;
        background: $white;
        color: $black;
        font-family: "Roboto";
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        box-sizing: border-box;
    }

    .ag-header-cell.header-year-month {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.625rem 0.75rem;
        text-align: center;
        gap: 0.625rem;
        border-right: 1px solid $backgroundTwo;
        background: $backgroundThree;
        color: $Light_Blue_PRI;
        font-family: "Roboto";
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        box-sizing: border-box;

        .ag-header-cell-label {
            justify-content: center;
            white-space: pre-wrap;
            /* Allows wrapping and respects new lines */
            text-align: center;
        }
    }

    .ag-cell.sticky-column {
        justify-content: flex-start;
    }

    .ag-cell {
        border-right: 1px solid $backgroundTwo;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.625rem 0.75rem;
        color: $blackHeading;
        font-family: "Roboto";
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;

        .ag-cell-value {
            white-space: normal;
            word-wrap: break-word;
        }
    }

    .ag-cell.customer-cell {
        padding: 0;
        padding-left: 0.65rem;
    }

    .ag-cell.year-month {
        padding: 0;
    }

    .ag-row {
        border: 0 0 0 1px solid $backgroundTwo;
    }

    .ag-pinned-left-header {
        border-right: 0;
    }

    .ag-row:first-child {
        border-top: 0;
    }

    .ag-pinned-left-cols-container::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 0.75rem;
        background: linear-gradient(to right, #C8C8C8 4.49%, #FFF 94.31%);
        pointer-events: none;
        z-index: 1;
    }

    .ag-pinned-left-header::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 0.75rem;
        background: linear-gradient(to right, #C8C8C8 4.49%, #FFF 94.31%);
        /* Gradient effect */
        pointer-events: none;
        z-index: 1;
    }

    .ag-row-odd {
        background-color: $white;
    }
}