@import './sass/globals.scss';

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html.overflow-hidden {
  overflow: hidden;
}