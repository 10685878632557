@use 'src/sass/colors' as *;

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  &.rsb-loader-overlay{
    width: calc(200vw - 25rem);
  }
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 2px solid $Light_Blue_PRI;
  border-top: 2px solid $tertiaryGrey; 
  border-radius: 50%;
  width: 25px;
  height: 25px; 
  animation: spin 1s linear infinite; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
