:root {
  --loyal-color: #0561D1;
  --neutral-color: #D5E6FE;
  --atRisk-color: #83B6FD;
  --keyIssues-color: #F5B546;
  --keyRisks-color: #F77A4A;
}

.chartContainer {
  display: flex;
  align-items: center;
}

.chartLabels {
  font-family: 'Arial, sans-serif';
  font-size: 12px;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;

  .labelRow {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    .labelColor {
      font-weight: bold;
      margin-right: 5px;
      font-size: 1.5rem;
    }
  }
}

.percentStyle {
  color: #000;
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin-right: 4px;
}

.labelStyle {
  color: #000;
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.legendContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.loyal {
  .labelColor {
    color: var(--loyal-color);
  }
}

.neutral {
  .labelColor {
    color: var(--neutral-color);
  }
}

.atRisk {
  .labelColor {
    color: var(--atRisk-color);
  }
}

.keyIssues {
  .labelColor {
    color: var(--keyIssues-color);
  }
}

.keyRisks {
  .labelColor {
    color: var(--keyRisks-color);
  }
}