.content-container{

  .csat-customer-container{
    gap: 1rem;
    height: auto !important;
  }
  .left-section, .right-section{
    flex-grow: 1 !important;
    max-width: calc(50% - 0.5rem);
  }

  .mt-1rem{
    margin-top: 1rem;
  }

  .hidden{
    visibility: hidden;
  }
}