@use 'src/sass/colors'as *;
.user-section-in-header{
  color: $white;
  font-family: Roboto;
  
  .dropbtn{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    font-size: 12px;
    font-weight: 300;

    .username{
      text-transform: capitalize;
    }

    .profile-pic{
      width: 28px;
      height: 28px;
      border-radius: 28px;
    }
  }
  
  .dropdown-section {
    position: relative;
    display: inline-block;
    z-index: 2000;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    color: $black;
    background-color: $white;
    width: 71px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

    flex-direction: column;
    gap: 6px;
    padding: 8px;
    border-radius: 6px;
  }
  
  .dropdown-content .item {
    color: black;
    cursor: pointer;
    font-size: 12px;
  }
  
  .dropdown-section:hover .dropdown-content {display: flex;}
  
}