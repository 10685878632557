@use 'src/sass/colors' as *;

.feedback-details-container {
    display: flex;
    flex-direction: column;

    .custom-ag-grid-css.ag-theme-alpine .ag-pinned-left-cols-container::after {
        content: "";
        background: none !important;
    }

    .custom-ag-grid-css.ag-theme-alpine .ag-pinned-left-header::after {
        background: none !important;
    }

    .custom-ag-grid-css.ag-theme-alpine .ag-cell.unpinned-column {
        justify-content: start;
        color: $grayTwo;
        font-family: "Roboto";
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
    }

    .custom-ag-grid-css.ag-theme-alpine .ag-header-cell .ag-header-cell-label {
        justify-content: left;
    }

    .ag-body-vertical-scroll-viewport,
    .ag-body-horizontal-scroll-viewport {
        display: none;
    }

    .feedback-title-cell {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .feedback-text {
            color: $blackHeading;
            font-family: "Roboto";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
            white-space: normal;
            word-wrap: break-word;
        }

        .feedback-text-click {
            color: $td_blue_dark;
            font-weight: 500;
        }

        .arrow-right {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }

    .timeline-container {
        position: relative;
        width: 100%;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .timeline-bar {
            height: 10px;
            background: $Light_Blue_PRI;
            position: absolute;
            left: 0;
            transition: width 0.3s ease-in-out;
        }
    }
}

.feedback-status {
    display: flex;
    flex-direction: column;
    padding: 8px 12px 8px 0px;
    gap: 10px;

    .label-style {
        font-family: "Roboto";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        padding: 6px;
        border-radius: 5px;
        align-self: flex-start;
    }

    .neutral-label {
        border: 1px solid $td_yellow_dark;
        background: $td_yellow_dark_background;
    }

    .positive-label {
        border: 1px solid $Green;
        background: $td_green_dark_background;
    }

    .negative-label {
        border: 1px solid $red;
        background: $td_red_dark_background;
    }
}

.insight-details-status {
    padding: 0px;
}

.execution-project-timeline-popup {
    display: flex;
    position: absolute;
    top: 100px;
    flex-direction: column;
    gap: 0.5px;
    background: $white;
    z-index: 1000;
    border-radius: 0.5rem;
    margin: 0.5rem;
    box-shadow: 0px 0px 4px 0px #00000040;
    width: 36vw;

    .project-name-view {
        padding: 12px 16px 0px 12px;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 0.5rem;
        padding: 10px 16px 12px 12px;

        .owner-profile-view {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;

            .owner-view {
                background: $grayThree;
                padding: 0.3rem;
                border-radius: 0.5rem;
                align-content: center;
            }

            .profile-view {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;

                font-size: 12px;
                font-weight: 300;

                .username {
                    text-transform: capitalize;
                }
            }
        }

        .lastUpdated-view {
            align-self: center;
            text-align: end;
        }
    }

    .table-row {
        display: flex;
        flex-direction: row;
        border-top: 1px solid $grayThree;

        .table-row-title {
            background: $LightBlue;
            padding: 0px 16px 0px 12px;
            width: 100px;
            height: 40px;
            align-content: center;
            flex-shrink: 0;
        }

        .table-row-value {
            padding: 0px 10px 0px 10px;
            align-content: center;
        }
    }
}