@use 'src/sass/colors' as *;

.in-body-custom-dropdown-wrapper {
    .css-b62m3t-container {
        font-family: "Roboto";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .css-t3ipsp-control {
        border-left: 1px solid $Light_Blue_PRI;

        &:hover {
            border-left: 1px solid $Light_Blue_PRI;
        }
    }
}

.general-select {
    &.css-13cymwt-control {
        border: 1px solid $tertiaryGrey;
    }

    .css-19bb58m,
    .css-1dimb5e-singleValue {
        color: $grayTwo;
    }

    .css-1xc3v61-indicatorContainer {
        padding-right: 10px;
    }

    .css-15lsz6c-indicatorContainer {
        padding-right: 10px;
    }

    .css-1u9des2-indicatorSeparator {
        display: none;
    }

    .css-1dimb5e-singleValue {
        text-align: start;
    }

    .css-1nmdiq5-menu {
        margin: 1px 0 0 0;

        .css-qr46ko {
            padding: 0.5rem 0;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        .list-item-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.25rem 1.25rem;
            cursor: pointer;

            .list-item-child-wrapper {
                display: flex;
                flex: 1 1;
                gap: 0.375rem;

                .select-input-tye {
                    width: 1.25rem;
                    height: 1.25rem;
                    flex-shrink: 0;
                    margin: 0;

                    &.active {
                        width: 1.375rem;
                        height: 1.375rem;
                    }
                }
            }

            &:hover {
                background: $graySeven;
            }

            .list-item-child-add-new {
                display: flex;
                flex: 1 1;
                gap: 0.375rem;
                align-items: center;
            }
        }
    }
}