@use 'src/sass/colors'as *;

.nbc-metric-editor-wrapper {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    padding: 0rem 1rem;
    align-items: center;
    background: $white;

    .text-container {
        flex: 1 1;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .plus-icon-image {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        position: absolute;
        padding: 0 1.5rem;
        right: 0;
    }
}

.nb-custom-metric-right-sidebar {
    input {
        display: flex;
        width: 6.5rem;
        padding: 8px 20px;
        align-items: center;
        align-self: stretch;
        box-sizing: border-box;
        border-radius: 0.25rem;
        border: 0.75px solid $grayNine;
        background: $white;
        outline: none;
        color: $grayTwo;
    }

    .overall-aggregate-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .all-project-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow: auto;
        gap: 1.75rem;
        align-items: flex-start;

        .each-project-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            width: 100%;

            .project-metric-value-wrapper {
                display: flex;
                gap: 0.5rem;
                width: 100%;

                .label-text {
                    width: 100%;
                    white-space: normal;
                }
            }
        }
    }

    .add-new-project-wrapper {
        .blue-plus-icon {
            width: 1.5rem;
            height: 1.5rem;
        }

        .add-new-button {
            color: $Light_Blue_PRI;
            padding: 5px 8px;
            box-sizing: border-box;
            width: 106px;
            justify-content: flex-start;
        }
    }
}