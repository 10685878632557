.progress-bar-container {
    width: 100%;
    height: 6px;
    background-color: #f3f3f3;
    border-radius: 6px;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.5s ease-in-out;
}
